@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:wght@400;600&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body,
html {
  /* we might not actually want this on `html` */
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: 'Red Hat Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  /* font-size: 14px; */
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Red Hat Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
